/* heywow-light */
@font-face {
  font-family: 'HeyWow';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local('HeyWow Light'), local('HeyWow-Light'), url('./fonts/heywow/HeyWow-Light.otf') format('opentype'),
    url('./fonts/heywow/HeyWow-Light.ttf') format('ttf');
}

/* heywow-regular */
@font-face {
  font-family: 'HeyWow';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('HeyWow Regular'), local('HeyWow-Regular'),
    url('./fonts/heywow/HeyWow-Regular.otf') format('opentype'),
    url('./fonts/heywow/HeyWow-Regular.ttf') format('ttf');
}
/* heywow-medium */
@font-face {
  font-family: 'HeyWow';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local('HeyWow Medium'), local('HeyWow-Medium'),
    url('./fonts/heywow/HeyWow-Medium.otf') format('opentype'),
    url('./fonts/heywow/HeyWow-Medium.ttf') format('ttf');
}

/* heywow-semi-bold */
@font-face {
  font-family: 'HeyWow';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local('HeyWow Semi Bold'), local('HeyWow-Semi-Bold'),
    url('./fonts/heywow/HeyWow-SemiBold.otf') format('opentype'),
    url('./fonts/heywow/HeyWow-SemiBold.ttf') format('ttf');
}

/* heywow-bold */
@font-face {
  font-family: 'HeyWow';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('HeyWow Bold'), local('HeyWow-Bold'), url('./fonts/heywow/HeyWow-Bold.otf') format('opentype'),
    url('./fonts/heywow/HeyWow-Bold.ttf') format('ttf');
}

/* heywow-extra-bold */
@font-face {
  font-family: 'HeyWow';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: local('HeyWow Extra Bold'), local('HeyWow-Extra-Bold'),
    url('./fonts/heywow/HeyWow-ExtraBold.otf') format('opentype'),
    url('./fonts/heywow/HeyWow-ExtraBold.ttf') format('ttf');
}

/* heywow-heavy */
@font-face {
  font-family: 'HeyWow';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: local('HeyWow Heavy'), local('HeyWow-Heavy'), url('./fonts/heywow/HeyWow-Heavy.otf') format('opentype'),
    url('./fonts/heywow/HeyWow-Heavy.ttf') format('ttf');
}

body {
  margin: 0;
  font-family: 'HeyWow' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: 'HeyWow';
}

.swiper {
  width: 100%;
  height: 100%;
  padding-left: 5px;
}

.swiper-slide {
  text-align: center;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-wrapper .swiper-slide {
  width: auto;

  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 0;
}

.swiper-wrapper .swiper-slide:last-child {
  padding-right: 5px;
}

/* css loading spinner */
.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 40%;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
